<template>
  <div class="noor-payment-container">
    <div class="noor-payment-container-box" v-if="hideCheckout">
      <p class="noor-payment-container-title">{{ $t("payment method") }}</p>
      <p class="noor-payment-container-subtitle">
        {{ $t("Please select your payment method") }}
      </p>
      <div class="noor-payment-container-methods">
        <div
          v-if="showCreditCard"
          class="noor-payment-container-methods-creditCard"
          @click="showCheckout"
        >
          <p>{{ $t("credit card") }}</p>
          <img src="@/assets/icons/credit_card_logo.png" alt />
        </div>
        <!-- Apple Pay button -->

        <div class="noor-payment-container-methods-apple-pay">
          <apple-pay-button
            buttonstyle="black"
            type="plain"
            @click="handleApplePayClick()"
            locale="en"
          ></apple-pay-button>
          <!-- <img src="@/assets/icons/apple-pay-logo.svg" alt /> -->
        </div>
        <!-- Paypalsub Payment -->
        <div
          class="noor-payment-container-methods-paypalsub"
          v-if="enablePaypal"
        >
          <paypalSub
            :gatewaydetail="paypalSubDetail"
            :paymentDetail="purchaseDetail"
          ></paypalSub>
        </div>
      </div>
    </div>
    <div class="noor-payment-container-checkout" v-else>
      <cardCheckout
        :checkoutDetail="checkoutDetail"
        :paymentDetail="purchaseDetail"
        :finalPromoAmounts="finalPromoAmounts"
        @closeCheckout="() => (hideCheckout = true)"
        :paymentPrecheckData="precheckData"
      ></cardCheckout>
    </div>
  </div>
</template>

<script>
//import merchantId from "@/public/apple-developer-merchantid-domain-association.txt";
import { mapActions } from "vuex";
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";

export default {
  props: {
    purchaseDetail: {
      type: Object,
    },
    checkoutDetail: {
      type: Object,
    },
    paypalDetail: {
      type: Object,
    },
    paypalSubDetail: {
      type: Object,
    },
    finalPromoAmounts: {
      type: Object,
    },
    precheckData: {
      type: Object,
    },
  },
  data() {
    return {
      hideCheckout: true,
      enablePaypal: true,
      showApplePayButton: false,
      appleData: null,
      //merchantId: merchantId
      showCreditCard: true,
      currentUserCountry: null,
			planDiscountCheckout: null,
			subscriberDetails: null,
			currentUserCountry: null,
			amountToShowOnApple:null
    };
  },
  computed: {
    ...mapGetters(["appConfig"]),
  },
  watch: {
    finalPromoAmounts(val) {
      if (val.isCoupon) {
        this.enablePaypal = false;
      } else if (!val.isCoupon) {
        this.enablePaypal = true;
      }
    },
  },
  mounted() {},
  created() {
		eventBus.$on("finalPromoAmounts", this.getApplePayButtonStatus);
    console.log("paypal sub details", this.paypalSubDetail);
		this.subscriberDetails = JSON.parse(localStorage.getItem("subscriberDetails")) ? JSON.parse(localStorage.getItem("subscriberDetails")) : null;
    console.log("subscriberDetails", this.subscriberDetails);
    let subscribedUserCountry = this.subscriberDetails ? this.subscriberDetails.data?.subscribercountry : null;
    console.log("77777", subscribedUserCountry);
    this.currentUserCountry = localStorage.getItem("currentUserCountry")
        ? JSON.parse(localStorage.getItem("currentUserCountry"))
        : null;
	  this.planDiscountCheckout = this.appConfig.planDiscount[subscribedUserCountry ? subscribedUserCountry : this.currentUserCountry.CountryCode] ? this.appConfig.planDiscount[subscribedUserCountry ? subscribedUserCountry : this.currentUserCountry.CountryCode] : this.appConfig.planDiscount["DEFAULT"];
  },
  mounted() {
    this.showCreditCardMethod();
		this.amountToShowOnApple = this.finalPromoAmounts
            ? (this.finalPromoAmounts.totalFinalCost / 100).toFixed(2)
            : (this.purchaseDetail.detail.amount / 100).toFixed(2)
  },
  methods: {
    ...mapActions([
      "validateTheSession",
      "paymentInitiation",
      "applePaymentConfirmation",
    ]),
		getApplePayButtonStatus(data){
			//console.log("DATACHECK",data)
			this.amountToShowOnApple = data
				? (data.totalFinalCost / 100).toFixed(2)
				: (this.purchaseDetail.detail.amount / 100).toFixed(2)
		},
    showCheckout() {
      this.hideCheckout = false;
    },
    paymentInit(token) {
      let currentDomain = window.location.origin;
      console.log("APPLE PAY1", currentDomain);
			let payload;
			// if(this.purchaseDetail.detail.planid === "2NJKC6IT"){
			// 	payload = {
			// 		gatewayid: "CHECKOUT",
			// 		params: {
			// 			amount: this.planDiscountCheckout.offerPrice,
			// 			currency: this.purchaseDetail.detail.currency,
			// 			devicetype: "WEB",
			// 			transactionpurpose: this.purchaseDetail.transactionPurpose,
			// 			transactionmode: "APPLEPAY",
			// 			availabilityid: this.purchaseDetail.detail.availabilityset[0],
			// 			planid: this.purchaseDetail.detail.planid,
			// 		},
			// 	};
			// }else {
       payload = {
        gatewayid: "CHECKOUT",
        params: {
          amount: this.finalPromoAmounts
            ? this.finalPromoAmounts.totalFinalCost
            : this.purchaseDetail.detail.amount,
          currency: this.purchaseDetail.detail.currency,
          devicetype: "WEB",
          transactionpurpose: this.purchaseDetail.transactionPurpose,
          transactionmode: "APPLEPAY",
          availabilityid: this.purchaseDetail.detail.availabilityset[0],
          planid: this.purchaseDetail.detail.planid,
        },
      };
			//}

      if (this.finalPromoAmounts && this.finalPromoAmounts.couponCode) {
        payload.params.couponid = this.finalPromoAmounts.couponCode;
      }

      // payload.params.client_return_url =
      //   currentDomain + "/profile" + "?" + "planid=" + this.purchaseDetail.detail.planid;

      console.log("APPLE PAY INIT", payload);

      this.loader = true;
      this.paymentInitiation(payload)
        .then((response) => {
          this.loader = false;
          if (!response.data.reason) {
            //alert("payment inint success")
            // debugger;

            const referencedata = response.data.referencedata;
            referencedata.paymentData = token.paymentData;
            let payload = {
              //gatewayid: "applepay",
              referencedata: JSON.stringify(referencedata),
              referenceid: response.data.referenceid,
            };
            this.applePaymentConfirmation(payload)
              .then((response) => {
                console.log("AppleResponse-1", response.data.transactionstatus);
                console.log("AppleResponse-2", response.data);
                if (response.data.transactionstatus) {
		session.completePayment(ApplePaySession.STATUS_SUCCESS)
                  eventBus.$emit("payment-success", response.data.referenceid);
                } else {
		session.completePayment(ApplePaySession.STATUS_FAILURE)
                  eventBus.$emit("payment-success", response.data.referenceid);
                }
              })
              .catch((error) => console.log(error));
          } else {
            this.formError = response.data.reason;
          }
        })
        .catch((error) => console.log(error));
    },

    handleApplePayClick() {
      var request = {
        countryCode: "US",
        currencyCode: "USD",
        supportedNetworks: ["visa", "masterCard", "amex", "discover"],
        merchantCapabilities: ["supports3DS"],
        total: {
          label: "Your Merchant Name",
          amount: this.amountToShowOnApple,
        },
      };
      var session = new ApplePaySession(3, request);
      window.session = session;
      window.session.begin();

      console.log("SESSIONN", session);

      window.session.onvalidatemerchant = (event) => {
        let payload = {
          applepayurl: event.validationURL,
        };
        this.validateTheSession(payload).then((response) => {
          window.response = response.data;
          this.appleData = response.data;
          console.log("window.response", response.data);
          window.session.completeMerchantValidation(this.appleData);

          // eventBus.$emit("test")
        });
      };

      // eventBus.$on("test", () => {
      // 	console.log("window.response from event", this.appleData);
      // window.session.completeMerchantValidation(this.appleData);

      // })

      // setTimeout(()  => {

      // 		try {
      //  console.log("this.appleData",this.appleData)
      // 						window.session.completeMerchantValidation(this.appleData);
      // 					} catch (e) {
      // 						console.error(e)
      // 						}
      window.session.onpaymentauthorized = (event) => {
        //alert("inside-onpaymentauthorized")
        // Send payment for processing...
        const payment = event.payment;
        console.log("PAYMENTTT", payment);
        this.paymentInit(payment.token);
      };

      // }, 2000);
    },
    // validateTheSession() {
    // // we will send the validation URL to our backend

    // },
    getApplePaySession(url) {
      return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open("POST", "/api/session/create");
        xhr.onload = function () {
          if (this.status >= 200 && this.status < 300) {
            resolve(JSON.parse(xhr.response));
          } else {
            reject({
              status: this.status,
              statusText: xhr.statusText,
            });
          }
        };

        xhr.onerror = function () {
          reject({
            status: this.status,
            statusText: xhr.statusText,
          });
        };

        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.send(JSON.stringify({ validationUrl: url }));
      });
    },
    showCreditCardMethod() {
      if (localStorage.getItem("currentUserCountry")) {
        this.currentUserCountry = localStorage.getItem("currentUserCountry")
          ? JSON.parse(localStorage.getItem("currentUserCountry"))
          : null;
        let filterCountries = this.appConfig.countryFilter;
        console.log(
          filterCountries.includes(this.currentUserCountry.CountryCode)
        );
        if (filterCountries.includes(this.currentUserCountry.CountryCode)) {
          this.showCreditCard = false;
        }
      }
    },
  },
  components: {
    paypal: () =>
      import(
        /* webpackChunkName: "paypal" */ "@/components/gateways/paypal.vue"
      ),
    cardCheckout: () =>
      import(
        /* webpackChunkName: "cardCheckout" */ "@/components/gateways/cardCheckout.vue"
      ),
    paypalSub: () =>
      import(
        /* webpackChunkName: "paypalSub" */ "@/components/gateways/paypalSub.vue"
      ),
  },
	beforeDestroy() {
    eventBus.$off("finalPromoAmounts", this.getApplePayButtonStatus);
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
apple-pay-button {
  --apple-pay-button-width: 247px;
  --apple-pay-button-height: 37px;
  --apple-pay-button-border-radius: 3px;
  --apple-pay-button-padding: 0px 7px;
  --apple-pay-button-box-sizing: border-box;
}
.noor-payment-container {
  background-color: $clr-dark-gd5;
  &-box {
    width: 70%;
    margin: 2.2rem auto 0rem;
  }
  &-checkout {
    width: 70%;
    margin: 2.2rem auto 0rem;
  }
  &-title {
    font-family: $font-regular;
    text-align: center;
    color: #ffffff;
  }
  &-subtitle {
    margin-top: 0.7rem;
    font-family: $font-regular;
    text-align: center;
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.5);
  }
  &-methods {
    // margin-top: 1.4rem;
    margin: 20px 0;
    &-creditCard {
      display: flex;
      justify-content: space-between;
      border: 0.1rem solid #b7b7b7;
      // padding: 0.3rem 0.6rem;
      padding: 0 0.6rem;
      width: 100%;
      // margin-bottom: 20px;
      margin: 20px 0;
      border-radius: 5px;
      cursor: pointer;
      p {
        display: flex;
        align-self: center;
        color: $clr-light-gd2;
        font-family: $font-regular;
      }
      img {
        width: 35px;
        height: 35px;
      }
    }

    &-apple-pay {
      background: #000000;
      // border: 0.1rem solid #000000;
      display: flex;
      justify-content: center;
      padding: 0 0.6rem;
      width: 100%;

      border-radius: 5px;
      cursor: pointer;

      img {
        width: 40px;
        height: 40px;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .noor-payment-container {
    &-box {
      margin: 0.8rem auto 0rem;
    }
    &-checkout {
      width: auto;
      margin: 2rem;
    }
  }
}
</style>
